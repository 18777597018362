/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
// import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

export const onInitialClientRender = () => {
  AOS.init({
    duration: 700,
    easing: "ease-in-out",
  })
}
